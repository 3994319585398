module.exports = global.config = {
  i18n: {
      welcome: {
          en: "Welcome",
          fa: "خوش آمدید"
      }
      // rest of your translation object
  },

  urlBase: "https://validadocumentos.coacalco.gob.mx/api-validador/validador"

  // other global config variables you wish
};