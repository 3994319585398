import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
} from "reactstrap";

import axios from "axios";

import React, { Component } from "react";
import { login } from "../components/utils/Contratos.js";

import { Alert } from "reactstrap";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";

import { app } from "config/firebase-config.js";
import {
  getAuth,
  signInWithEmailAndPassword
} from "firebase/auth";

const authentication = getAuth();
class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      usuario: "",
      pass: "",
      buttonDesactivo: true,
      mostrarAlerta: false,
      mensajeAlert: "",
    };
  }

  generarToken = async () => {
    login.email = this.state.usuario;
    login.pass = this.state.pass;

    const request = await axios.post(
      global.config.urlBase + "/api/token",
      login
    );

    if (request.status === 200) {
      if (request.data.token === "") {
        this.setState({
          mostrarAlerta: true,
          mensajeAlert: request.data.mensaje.mensaje,
        });
      } else {
        let token = request.data.token;

        Cookies.set("token", request.data.token);

        let decoded = jwt_decode(token);

        Cookies.set("id", decoded.id);
        Cookies.set("name", decoded.name);
        Cookies.set("rol", decoded.rol);

        if ("ROLE_ADMIN" === decoded.rol) {

          window.location.href = "/admin/usuarios";
        } else {
         
          window.location.href = "/admin/folios";
        }
      }
    }

    /*
    if (request.status === 200) {
      if (request.data.token === "") {
        this.setState({
          mostrarAlerta: true,
          mensajeAlert: request.data.mensaje.mensaje,
        });
      } else {
        let token = request.data.token;

        Cookies.set("token", request.data.token);

        let decoded = jwt_decode(token);


        Cookies.set("id", decoded.id);
        Cookies.set("name", decoded.name);
        Cookies.set("rol", decoded.rol);

        if ("ROLE_ADMIN" === decoded.rol) {
         
          let url = window.location.href.replace("auth/login", "");

        window.location.href = "/admin/usuarios";
        } else {

          let url = window.location.href.replace("auth/login", "");

        window.location.href = "/admin/folios";
         
        }
      }
    }
    */
  };

  login = async () => {
    login.usuario = this.state.usuario;
    login.pass = this.state.pass;

    /*
    const request = await axios.post(
      global.config.urlBase + "/api/token",
      login
    );
    */

    signInWithEmailAndPassword(
      authentication,
      this.state.usuario,
      this.state.pass
    )
      .then((response) => {
        console.log(response);

        this.generarToken();
      })
      .catch((error) => {
        console.log(error);

        this.setState(
          {
            mostrarAlerta: true,
            mensajeAlert: "Datos incorrectos",
          },
          () => {
            let _exQualityInterval = setTimeout(() => {
              this.setState({ mostrarAlerta: false });
              clearInterval(_exQualityInterval);
            }, 3000);
          }
        );
      });

    /*
    if (request.status === 200) {
      if (request.data.token === "") {
        this.setState({
          mostrarAlerta: true,
          mensajeAlert: request.data.mensaje.mensaje,
        });
      } else {
        let token = request.data.token;

        Cookies.set("token", request.data.token);

        let decoded = jwt_decode(token);


        Cookies.set("id", decoded.id);
        Cookies.set("name", decoded.name);
        Cookies.set("rol", decoded.rol);

        if ("ROLE_ADMIN" === decoded.rol) {
         
          let url = window.location.href.replace("auth/login", "");

        window.location.href = "/admin/usuarios";
        } else {

          let url = window.location.href.replace("auth/login", "");

        window.location.href = "/admin/folios";
         
        }
      }
    }
    */
  };

  handleChange = (e) => {
    var valorUser = "";
    var valorPass = "";
    if (e.target.name === "usuario") {
      valorUser = e.target.value;

      this.setState({ usuario: valorUser });
    } else if (e.target.name === "pass") {
      valorPass = e.target.value;
      this.setState({ pass: valorPass });
    }

    this.validarEnvio();
  };

  validarEnvio = () => {
    if (this.state.usuario !== "" && this.state.pass !== "") {
      let usuario = this.state.usuario;

      const regex = new RegExp(
        "(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?"
      );

      if (regex.test(usuario)) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  };

  componentDidMount = async () => {
    // DESTRUCCION DE COOKIES
    Cookies.remove("id");
    Cookies.remove("name");
    Cookies.remove("rol");
    Cookies.remove("token");
  };

  render() {
    return (
      <>
        <Col lg="5" md="7">
          {this.state.mostrarAlerta ? (
            <Alert color="danger">
              <strong>Error!</strong> {this.state.mensajeAlert}!
            </Alert>
          ) : null}
          <Card className="bg-secondary shadow border-0">
            <CardHeader className="bg-transparent pb-5">
              <div className="text-muted text-center mt-2 mb-3">
                <small>Login</small>
              </div>
            </CardHeader>
            <CardBody className="px-lg-5 py-lg-5">
              <Form role="form">
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      value={this.state.usuario}
                      onChange={this.handleChange}
                      name="usuario"
                      placeholder="Email"
                      type="email"
                      autoComplete="new-email"
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      value={this.state.pass}
                      onChange={this.handleChange}
                      name="pass"
                      placeholder="Password"
                      type="password"
                      autoComplete="new-password"
                    />
                  </InputGroup>
                </FormGroup>

                <div className="text-center">
                  <Button
                    className="my-4"
                    color="primary"
                    type="button"
                    disabled={this.validarEnvio()}
                    onClick={() => this.login()}
                  >
                    Ingresar
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
          <Row className="mt-3">
            <Col xs="6">
              <a
                className="text-light"
                href={window.location.href.replace("auth/login", "auth/pass")}
              >
                <small>Olvidaste tu password?</small>
              </a>
            </Col>
          </Row>
        </Col>
      </>
    );
  }
}

export default Login;
