import Index from "views/Index.js";
//import Profile from "views/examples/Profile.js";
//import Maps from "views/examples/Maps.js";
//import Register from "views/examples/Register.js";
import Login from "views/Login.js";
//import Tables from "views/examples/Tables.js";
//import Icons from "views/examples/Icons.js";
import Folios from "views/Folios.js";
import Usuarios from "views/Usuarios.js";
import RecuperaPass from "views/RecuperaPass";
import CambioPass from "views/CambioPass";
import InfoFolio from "views/InfoFolio";
import Credenciales from "views/Credenciales";

import Departamentos from "views/Departamentos";
import Firmantes from "views/Firmantes";
import FoliosAdmin from "views/FoliosAdmin";
import Graficas from "views/Graficas";
import Activar from "views/Activar";

var routes = [
  {
    path: "/index",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-primary",
    component: Index,
    layout: "/admin",
  },

  /*
   {
    path: "/icons",
    name: "Icons",
    icon: "ni ni-planet text-blue",
    component: Icons,
    layout: "/admin",
    rol :"ROLE_USUARIO",
    visible:true
  },
  
 
  {
    path: "/maps",
    name: "Maps",
    icon: "ni ni-pin-3 text-orange",
    component: Maps,
    layout: "/admin",
  },
  {
    path: "/user-profile",
    name: "User Profile",
    icon: "ni ni-single-02 text-yellow",
    component: Profile,
    layout: "/admin",
  },
  {
    path: "/tables",
    name: "Tables",
    icon: "ni ni-bullet-list-67 text-red",
    component: Tables,
    layout: "/admin",
  },
    {
    path: "/register",
    name: "Register",
    icon: "ni ni-circle-08 text-pink",
    component: Register,
    layout: "/auth",
  },

  */
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: Login,
    layout: "/auth",
  },

  {
    path: "/folios",
    name: "Folios",
    icon: "ni ni-book-bookmark text-info",
    component: Folios,
    layout: "/admin",
    rol: "ROLE_USUARIO",
    visible: true,
  },

  {
    path: "/pass",
    name: "Recuperate tu password",
    icon: "ni ni-circle-08 text-pink",
    component: RecuperaPass,
    layout: "/auth",
  },
  {
    path: "/cambiopass/:id",
    name: "cambio de passgoe",
    icon: "ni ni-circle-08 text-pink",
    component: CambioPass,
    layout: "/auth",

    visible: true,
  },

  {
    path: "/activacion/:id",
    name: "cambio de passgoe",
    icon: "ni ni-circle-08 text-pink",
    component: Activar,
    layout: "/auth",

    visible: true,
  },

  {
    path: "/usuarios",
    name: "Usuarios",
    icon: "ni ni-book-bookmark text-info",
    component: Usuarios,
    layout: "/admin",
    rol: "ROLE_ADMIN",
    visible: true,
  },
  {
    path: "/foliosfull",
    name: "Folios",
    icon: "ni ni-book-bookmark text-info",
    component: FoliosAdmin,
    layout: "/admin",
    rol: "ROLE_ADMIN",
    visible: true,
  },
  {
    path: "/firmantes/:id",
    name: "Firmantes",
    icon: "ni ni-book-bookmark text-info",
    component: Firmantes,
    layout: "/admin",
    rol: "ROLE_USUARIO",
    visible: false,
  },
  {
    path: "/credenciales",
    name: "Credenciales",
    icon: "ni ni-book-bookmark text-info",
    component: Credenciales,
    layout: "/admin",
    rol: "ROLE_USUARIO",
    visible: false,
  },
  {
    path: "/departamentos",
    name: "Departamentos",
    icon: "ni ni-book-bookmark text-info",
    component: Departamentos,
    layout: "/admin",
    rol: "ROLE_USUARIO",
    visible: true,
  },
  {
    path: "/graficas",
    name: "Graficas",
    icon: "ni ni-book-bookmark text-info",
    component: Graficas,
    layout: "/admin",
    rol: "ROLE_USUARIO",
    visible: false,
  },
  {
    path: "/info/:id",
    name: "InfoFolio",
    icon: "ni ni-circle-08 text-pink",
    component: InfoFolio,
    layout: "/auth",
  },
];
export default routes;
