import {
  Button,
  Card,
  Table,
  CardBody,
  Modal,
  FormGroup,
  Form,
  Input,
  Col,
  CardHeader,
  CardFooter,
  Pagination,
  Container,
  Label,
  Row,
  Alert,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";

import { altaFirmante } from "../components/utils/Contratos.js";

import axios from "axios";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";

import React, { Component } from "react";
class Firmantes extends Component {
  constructor(props) {
    super(props);

    this.state = {
      exampleModal: false,
      listadoFirmantes: [],
      cuts: "",
      password: "",
      idFirmante: "",
      color: "danger",
      nombre: "",
      paterno: "",
      materno: "",
      idDepartamento:
        this.props.match.params.id === ":id" ? "" : this.props.match.params.id,
    };
  }

  toggleModal = (state) => {
    this.setState({
      [state]: !this.state[state],
    });
  };

  componentDidMount = async () => {
    console.log("this.state.idDepartamento");
    console.log(this.state.idDepartamento);

    if (!Cookies.get("token")) {
      window.location.href = "/login";
    }

    this.validarExpiracionToken();

    const folios = await axios.get(
      global.config.urlBase +
        "/firmante/listarFirmantes/" +
        this.state.idDepartamento,
      {
        headers: { Authorization: Cookies.get("token") },
      }
    );

    if (folios.status === 200) {
      let listadoFirmantes = folios.data.resultado;

      this.setState({
        listadoFirmantes: listadoFirmantes,
      });
    } else {
    }
  };

  validarExpiracionToken = async () => {
    // VAMOS A VALIDAR LA EXPIRACION DEL TOKEN

    let decoded = jwt_decode(Cookies.get("token"));
    let dateNow = new Date();
    let expiracion = Number(decoded.exp) * 1000;
    let dateToken = new Date(expiracion);

    if (dateToken.getTime() < dateNow.getTime()) {
      window.location.href = "/login";
    }
  };

  regresar = () => {

    window.location.href = "/admin/departamentos";
  };

  editarFirmante = (identificador, edicion) => {
    if (!edicion) {
      this.setState({
        cuts: "",
        password: "",
        idFirmante: "",
        nombre: "",
        paterno: "",
        materno: "",
      });
    } else {
      let firmante = this.state.listadoFirmantes.filter(
        (elemento) => elemento.idFirmante === identificador
      );

      console.log(firmante);

      let cuts = firmante[0].cuts;
      let password = firmante[0].password;
      let idFirmante = firmante[0].idFirmante;
      let nombre = firmante[0].nombre;
      let paterno = firmante[0].paterno;
      let materno = firmante[0].materno;
      console.log(cuts);
      console.log(password);

      console.log(idFirmante);

      this.setState({
        cuts: cuts,
        password: password,
        idFirmante: idFirmante,
        nombre: nombre,
        paterno: paterno,
        materno: materno,
      });
    }

    this.toggleModal("exampleModal");
  };

  cambioCheck = async (idFirmante) => {
    this.validarExpiracionToken();

    const cambio = await axios.get(
      global.config.urlBase + "/firmante/estatus/" + idFirmante,
      {
        headers: { Authorization: Cookies.get("token") },
      }
    );

    if (cambio.status === 200) {
      let listadoFirmantes = this.state.listadoFirmantes;

      this.setState({ listadoFirmantes: listadoFirmantes });
    }
  };

  renderTableData() {
    if (this.state.listadoFirmantes.length > 0) {
      return this.state.listadoFirmantes.map((firmante, index) => {
        return (
          <tr key={index}>
            <td className="text-center">{firmante.cuts}</td>
            <td className="text-center">{firmante.password}</td>

            <td className="text-center">
              {firmante.nombre +
                " " +
                firmante.paterno +
                " " +
                firmante.materno}
            </td>

            <td className="text-center">
              <label className="custom-toggle">
                <input
                  defaultChecked={firmante.estatus ? true : false}
                  onChange={() => this.cambioCheck(firmante.idFirmante)}
                  type="checkbox"
                />
                <span className="custom-toggle-slider rounded-circle" />
              </label>
            </td>

            <td className="text-center">
              <Button
                className="btn-icon btn-3"
                color="primary"
                type="button"
                onClick={() => this.editarFirmante(firmante.idFirmante, true)}
              >
                <span className="btn-inner--icon">
                  <i className="ni ni-ui-04" />
                </span>
              </Button>
            </td>
          </tr>
        );
      });
    }
  }

  handleChange = (e) => {
    var cuts = "";
    var password = "";
    var nombre = "";
    var paterno = "";
    var materno = "";

    if (e.target.name === "cuts") {
      cuts = e.target.value;

      this.setState({ cuts: cuts });
    } else if (e.target.name === "password") {
      password = e.target.value;
      this.setState({ password: password });
    } else if (e.target.name === "nombre") {
      nombre = e.target.value;
      this.setState({ nombre: nombre });
    } else if (e.target.name === "paterno") {
      paterno = e.target.value;
      this.setState({ paterno: paterno });
    } else if (e.target.name === "materno") {
      materno = e.target.value;
      this.setState({ materno: materno });
    }

    this.validarEnvio();
  };

  validarEnvio = () => {
    if (
      this.state.cuts !== "" &&
      this.state.password !== "" &&
      this.state.nombre !== "" &&
      this.state.paterno !== "" &&
      this.state.materno !== ""
    ) {
      return false;
    } else {
      return true;
    }
  };

  crearFirmante = async () => {
    this.toggleModal("exampleModal");

    this.validarExpiracionToken();

    altaFirmante.cuts = this.state.cuts;
    altaFirmante.password = this.state.password;
    altaFirmante.idDepartamento = this.state.idDepartamento;
    altaFirmante.idFirmante = this.state.idFirmante;

    altaFirmante.nombreFirmante = this.state.nombre;
    altaFirmante.apellidoPaternoFirmante = this.state.paterno;
    altaFirmante.apellidoMaternoFirmante = this.state.materno;

    try {
      const alta = await axios.post(
        global.config.urlBase + "/firmante/crear",
        altaFirmante,
        {
          headers: { Authorization: Cookies.get("token") },
        }
      );

      if (alta.status === 200) {
        this.setState(
          {
            mostrarAlerta: true,
            mensajeAlerta: alta.data.mensaje.mensaje,
            color: "success",
          },
          () => {
            let _exQualityInterval = setTimeout(() => {
              this.setState({ mostrarAlerta: false });
              clearInterval(_exQualityInterval);
              this.componentDidMount();
            }, 3000);
          }
        );
      }
    } catch {
      this.setState(
        {
          mostrarAlerta: true,
          mensajeAlerta: "PROBLEMA AL CREAR USUARIO INTENTE MAS TARDE",
          color: "danger",
        },
        () => {
          let _exQualityInterval = setTimeout(() => {
            this.setState({ mostrarAlerta: false });
            clearInterval(_exQualityInterval);
          }, 3000);
        }
      );
    }
  };

  render() {
    return (
      <>
        <Header />
        {/* Page content */}
        <Container className="mt--7" fluid>
          {this.state.mostrarAlerta ? (
            <Alert color={this.state.color}>
              <strong></strong> {this.state.mensajeAlerta}!
            </Alert>
          ) : null}
          {/* Table */}
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <h3 className="mb-0">Firmantes del departamento </h3>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col" className="text-center">
                        CUTS
                      </th>
                      <th scope="col" className="text-center">
                        PASSWORD
                      </th>

                      <th scope="col" className="text-center">
                        FIRMANTE
                      </th>

                      <th scope="col" className="text-center">
                        ESTATUS
                      </th>

                      <th scope="col" className="text-center">
                        EDITAR
                      </th>

                      <th scope="col" />
                    </tr>
                  </thead>
                  <tbody>{this.renderTableData()}</tbody>
                </Table>
                <CardFooter className="py-4">
                  <nav aria-label="...">
                    <Pagination
                      className="pagination justify-content-end mb-0"
                      listClassName="justify-content-end mb-0"
                    >
                      <Button
                        className="btn-icon btn-3"
                        color="primary"
                        type="button"
                        onClick={() => this.editarFirmante(null, false)}
                      >
                        <span className="btn-inner--icon">
                          <i className="ni ni-fat-add" />
                        </span>
                      </Button>
                    </Pagination>
                  </nav>
                </CardFooter>
              </Card>

              <Button
                color="primary"
                type="button"
                onClick={() => this.regresar()}
              >
                Regresar a departamentos
              </Button>
            </div>
          </Row>

          <Modal
            className="modal-dialog-centered"
            size="xs"
            isOpen={this.state.exampleModal}
            toggle={() => this.toggleModal("exampleModal")}
          >
            <div className="modal-body p-0">
              <Card className="bg-secondary shadow border-0">
                <CardHeader className="bg-transparent pb-5">
                  <div className="text-muted text-center mt-2 mb-3">
                    <small>Agregar o editar firmante</small>
                  </div>
                </CardHeader>
                <CardBody className="px-lg-5 py-lg-5">
                  <Form>
                    <Row>
                      <Col md="6">
                        <FormGroup>
                          <Label>Cuts</Label>
                          <Input
                            value={this.state.cuts}
                            onChange={this.handleChange}
                            name="cuts"
                            placeholder="cuts"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <Label>Password</Label>
                          <Input
                            value={this.state.password}
                            onChange={this.handleChange}
                            name="password"
                            placeholder="password"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col md="6">
                        <FormGroup>
                          <Label>Nombre</Label>
                          <Input
                            value={this.state.nombre}
                            onChange={this.handleChange}
                            name="nombre"
                            placeholder="nombre"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <Label>Apellido paterno</Label>
                          <Input
                            value={this.state.paterno}
                            onChange={this.handleChange}
                            name="paterno"
                            placeholder="paterno"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col md="6">
                        <FormGroup>
                          <Label>Apellido materno</Label>
                          <Input
                            value={this.state.materno}
                            onChange={this.handleChange}
                            name="materno"
                            placeholder="materno"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </div>

            <div className="modal-footer">
              <Button
                color="primary"
                type="button"
                disabled={this.validarEnvio()}
                onClick={() => this.crearFirmante()}
              >
                Guardar
              </Button>
              <Button
                className="ml-auto"
                color="link"
                data-dismiss="modal"
                type="button"
                onClick={() => this.toggleModal("exampleModal")}
              >
                Cancelar
              </Button>
            </div>
          </Modal>
        </Container>
      </>
    );
  }
}

export default Firmantes;
