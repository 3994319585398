// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCEmoj6NB5EuwfjWNfdUWoFwvuea1iX77Q",
  authDomain: "validador-95dac.firebaseapp.com",
  projectId: "validador-95dac",
  storageBucket: "validador-95dac.appspot.com",
  messagingSenderId: "1073463595519",
  appId: "1:1073463595519:web:38db86b3edf5c097c217ca",
  measurementId: "G-2BK3GBC3TC"
};

// Initialize Firebase
export const fire = initializeApp(firebaseConfig);
