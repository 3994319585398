import {
  Table,
  CardBody,
  Modal,
  Button,
  FormGroup,
  Form,
  Input,
  Col,
  Card,
  CardHeader,
  CardFooter,
  Pagination,
  Container,
  Label,
  Row,
  Alert,
  CardTitle,
  InputGroup,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";

import axios from "axios";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";

import { altaUsuario } from "../components/utils/Contratos.js";

import React, { Component } from "react";
class Usuarios extends Component {
  constructor(props) {
    super(props);

    this.state = {
      listadoUsuarios: [],
      exampleModal: false,
      nombre: "",
      apellido: "",
      mail: "",
      usuario: "",
      password: "",
      tipoUsuario: "2",
      mostrarAlert: false,
      mensajeAlert: "",
      color: "danger",
      totalUsuarios: "",
      filtrado: "",
    };
  }

  toggleModal = (state) => {
    this.setState({
      [state]: !this.state[state],
    });
  };

  filtrado = (e) => {
    console.log("no se");
    var filtrado = "";

    if (e.target.name === "filtrado") {
      filtrado = e.target.value;

      filtrado = filtrado.toUpperCase();

      this.setState({ filtrado: filtrado });
    }

    if (filtrado !== "") {
      console.log(this.state.listadoUsuarios);

      let listadoUsuarios = this.state.listadoUsuarios.filter((item) =>
        item.usuario.includes(filtrado)
      );

      console.log(listadoUsuarios);

      this.setState({ listadoUsuarios: listadoUsuarios });
    } else {
      this.componentDidMount();
    }
  };

  componentDidMount = async () => {
    this.validarEnvio();
    if (!Cookies.get("token")) {
      window.location.href = "/auth/login";
    }

    this.validarExpiracionToken();

    const usuarios = await axios.get(
      global.config.urlBase + "/usuario/listarUsuarios",
      {
        headers: { Authorization: Cookies.get("token") },
      }
    );

    if (usuarios.status === 200) {
      let listadoUsuarios = usuarios.data.resultado;

      this.setState({
        listadoUsuarios: listadoUsuarios,
        totalUsuarios: listadoUsuarios.length,
      });
    } else {
    }
  };

  validarExpiracionToken = async () => {
    // VAMOS A VALIDAR LA EXPIRACION DEL TOKEN

    let decoded = jwt_decode(Cookies.get("token"));
    let dateNow = new Date();
    let expiracion = Number(decoded.exp) * 1000;
    let dateToken = new Date(expiracion);

    if (dateToken.getTime() < dateNow.getTime()) {
      window.location.href = "/auth/login";
    }
  };

  cambioCheck = async (idUsuario) => {
    this.validarExpiracionToken();

    const cambio = await axios.get(
      global.config.urlBase + "/usuario/estatus/" + idUsuario,
      {
        headers: { Authorization: Cookies.get("token") },
      }
    );

    if (cambio.status === 200) {
      let listadoUsuarios = this.state.listadoUsuarios;

      this.setState({ listadoUsuarios: listadoUsuarios });
    }
  };

  renderTableData() {
    if (this.state.listadoUsuarios.length > 0) {
      return this.state.listadoUsuarios.map((usuario, index) => {
        return (
          <tr key={index}>
            <td className="text-center">{usuario.nombre}</td>
            <td className="text-center">{usuario.apellido}</td>
            <td className="text-center">{usuario.email}</td>
            <td className="text-center">{usuario.usuario}</td>

            <td className="text-center">
              <label className="custom-toggle">
                <input
                  defaultChecked={usuario.estatus ? true : false}
                  onChange={() => this.cambioCheck(usuario.idUsuario)}
                  type="checkbox"
                />
                <span className="custom-toggle-slider rounded-circle" />
              </label>
            </td>

            <td className="text-center">{this.calcularRol(usuario.idRol)}</td>
          </tr>
        );
      });
    }
  }

  calcularRol = (idRol) => {
    let rol = "";
    if (idRol === 1) {
      rol = "ADMINISTRADOR";
    } else if (idRol === 2) {
      rol = "USUARIO";
    } else {
      rol = "CONSUMO";
    }

    return rol;
  };
  handleChange = (e) => {
    var nombre = "";
    var apellidoPaterno = "";
    var email = "";
    var usuario = "";
    var password = "";

    if (e.target.name === "nombre") {
      nombre = e.target.value;

      this.setState({ nombre: nombre });
    } else if (e.target.name === "apellidoPaterno") {
      apellidoPaterno = e.target.value;
      this.setState({ apellido: apellidoPaterno });
    } else if (e.target.name === "email") {
      email = e.target.value;
      this.setState({ mail: email });
    } else if (e.target.name === "usuario") {
      usuario = e.target.value;
      this.setState({ usuario: usuario });
    } else if (e.target.name === "password") {
      password = e.target.value;
      this.setState({ password: password });
    }

    this.validarEnvio();
  };

  validarEnvio = () => {
    if (
      this.state.nombre !== "" &&
      this.state.apellido !== "" &&
      this.state.mail !== "" &&
      this.state.usuario !== "" &&
      this.state.password !== ""
    ) {
      return false;
    } else {
      return true;
    }
  };

  crearUsuario = async () => {
    this.toggleModal("exampleModal");

    this.validarExpiracionToken();

    altaUsuario.nombre = this.state.nombre;
    altaUsuario.apellidoPaterno = this.state.apellido;
    altaUsuario.email = this.state.mail;
    altaUsuario.usuario = this.state.usuario;
    altaUsuario.pass = this.state.password;
    altaUsuario.idRol = this.state.tipoUsuario;

    try {
      const alta = await axios.post(
        global.config.urlBase + "/usuario/crear",
        altaUsuario,
        {
          headers: { Authorization: Cookies.get("token") },
        }
      );

      if (alta.status === 200) {
        this.setState(
          {
            mostrarAlert: true,
            mensajeAlert: alta.data.mensaje.mensaje,
            color: "success",
          },
          () => {
            let _exQualityInterval = setTimeout(() => {
              this.setState({ mostrarAlert: false });
              clearInterval(_exQualityInterval);
              this.componentDidMount();
            }, 3000);
          }
        );
      }
    } catch {
      this.setState(
        {
          mostrarAlert: true,
          mensajeAlert: "PROBLEMA AL CREAR USUARIO INTENTE MAS TARDE",
          color: "danger",
        },
        () => {
          let _exQualityInterval = setTimeout(() => {
            this.setState({ mostrarAlert: false });
            clearInterval(_exQualityInterval);
          }, 3000);
        }
      );
    }
  };

  handleSelect = (e) => {
    if (e) {
      e.preventDefault();

      let value = e.target.value;
      this.setState({ tipoUsuario: value });
    }
  };

  render() {
    return (
      <>
        <Header />
        {/* Page content */}
        <Container className="mt--7" fluid>
          {/* Table */}

          {this.state.mostrarAlert ? (
            <Alert color={this.state.color}>
              <strong></strong> {this.state.mensajeAlert}!
            </Alert>
          ) : null}

          <Row>
            <div className="col">
              <div style={{ width: "18rem" }}>
                <Card className="card-stats mb-4 mb-lg-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle className="text-uppercase text-muted mb-0">
                          Total de usuarios
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          {this.state.totalUsuarios}
                        </span>
                      </div>
                      <Col className="col-auto"></Col>
                    </Row>
                    <p className="mt-3 mb-0 text-muted text-sm"></p>
                  </CardBody>
                </Card>
              </div>
            </div>
          </Row>

          <br></br>

          <Form>
            <Row>
              <Col md="12">
                <FormGroup>
                  <InputGroup className="mb-4">
                    <Input
                      placeholder="Busqueda por usuario"
                      type="text"
                      onChange={this.filtrado}
                      value={this.state.filtrado}
                      name="filtrado"
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
            </Row>
          </Form>

          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <h3 className="mb-0">Usuarios</h3>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col" className="text-center">
                        Nombre
                      </th>
                      <th scope="col" className="text-center">
                        Apellido Paterno
                      </th>
                      <th scope="col" className="text-center">
                        Email
                      </th>
                      <th scope="col" className="text-center">
                        Usuario
                      </th>
                      <th scope="col" className="text-center">
                        Estatus
                      </th>
                      <th scope="col" className="text-center">
                        Rol
                      </th>
                      <th scope="col" />
                    </tr>
                  </thead>
                  <tbody>{this.renderTableData()}</tbody>
                </Table>
                <CardFooter className="py-4">
                  <nav aria-label="...">
                    <Pagination
                      className="pagination justify-content-end mb-0"
                      listClassName="justify-content-end mb-0"
                    >
                      <Button
                        className="btn-icon btn-3"
                        color="primary"
                        type="button"
                        onClick={() => this.toggleModal("exampleModal")}
                      >
                        <span className="btn-inner--icon">
                          <i className="ni ni-fat-add" />
                        </span>
                      </Button>
                    </Pagination>
                  </nav>
                </CardFooter>
              </Card>
            </div>
          </Row>

          <Modal
            className="modal-dialog-centered"
            size="xs"
            isOpen={this.state.exampleModal}
            toggle={() => this.toggleModal("exampleModal")}
          >
            <div className="modal-body p-0">
              <Card className="bg-secondary shadow border-0">
                <CardHeader className="bg-transparent pb-5">
                  <div className="text-muted text-center mt-2 mb-3">
                    <small>Agregar Usuario Nuevo</small>
                  </div>
                </CardHeader>
                <CardBody className="px-lg-5 py-lg-5">
                  <Form>
                    <Row>
                      <Col md="6">
                        <FormGroup>
                          <Label>Nombre</Label>
                          <Input
                            value={this.state.nombre}
                            onChange={this.handleChange}
                            name="nombre"
                            placeholder="Nombre"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <Label>Apellido</Label>
                          <Input
                            value={this.state.apellido}
                            onChange={this.handleChange}
                            name="apellidoPaterno"
                            placeholder="Apellido"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                        <FormGroup>
                          <Label>Email</Label>
                          <Input
                            value={this.state.mail}
                            onChange={this.handleChange}
                            name="email"
                            placeholder="email"
                            type="email"
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <Label>Usuario</Label>
                          <Input
                            value={this.state.usuario}
                            onChange={this.handleChange}
                            name="usuario"
                            placeholder="usuario"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col md="6">
                        <FormGroup>
                          <Label>password</Label>
                          <Input
                            value={this.state.password}
                            onChange={this.handleChange}
                            name="password"
                            placeholder="password"
                            type="password"
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <Label>Tipo usuario</Label>
                          <Input type="select" onChange={this.handleSelect}>
                            <option value={1}>USUARIO</option>
                          </Input>
                        </FormGroup>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </div>

            <div className="modal-footer">
              <Button
                color="primary"
                type="button"
                disabled={this.validarEnvio()}
                onClick={() => this.crearUsuario()}
              >
                Guardar
              </Button>
              <Button
                className="ml-auto"
                color="link"
                data-dismiss="modal"
                type="button"
                onClick={() => this.toggleModal("exampleModal")}
              >
                Cancelar
              </Button>
            </div>
          </Modal>
        </Container>
      </>
    );
  }
}

export default Usuarios;
