import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
} from "reactstrap";

import axios from "axios";

import React, { Component } from "react";
import { cambioPass } from "../components/utils/Contratos.js";

import { Alert } from "reactstrap";

class Activar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      buttonDesactivo: false,
      mostrarAlerta: false,
      mensajeAlert: "",
      color: "danger",
      tokenCambio:
        this.props.match.params.id === ":id" ? "" : this.props.match.params.id,
    };
  }

  cambio = async () => {
    cambioPass.password = this.state.email;
    cambioPass.token = this.state.tokenCambio;

    const request = await axios.post(
      global.config.urlBase + "/usuario/activacion",
      cambioPass
    );

    if (request.status === 200) {
      if (request.data.mensaje.estatus) {
        this.setState(
          {
            mostrarAlerta: true,
            mensajeAlert: request.data.mensaje.mensaje,
            color: "success",
          },
          () => {
            let _exQualityInterval = setTimeout(() => {
              this.setState({ mostrarAlerta: false });
              clearInterval(_exQualityInterval);
              window.location.href = "/auth/login";
            }, 3000);
          }
        );
      } else {
        this.setState(
          {
            mostrarAlerta: true,
            mensajeAlert: request.data.mensaje.mensaje,
            color: "danger",
          },
          () => {
            let _exQualityInterval = setTimeout(() => {
              this.setState({ mostrarAlerta: false });
              clearInterval(_exQualityInterval);
            }, 3000);
          }
        );
      }
    }
  };

  handleChange = (e) => {
    var email = "";
    if (e.target.name === "email") {
      email = e.target.value;

      this.setState({ email: email });
    }

    this.validarEnvio();
  };

  validarEnvio = () => {
    if (this.state.email !== "") {
      let mail = this.state.email;
      const regex = new RegExp(
        "(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?"
      );

      if (regex.test(mail)) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  };

  componentDidMount = async () => {};

  render() {
    return (
      <>
        <Col lg="5" md="7">
          {this.state.mostrarAlerta ? (
            <Alert color={this.state.color}>
              <strong></strong> {this.state.mensajeAlert}!
            </Alert>
          ) : null}
          <Card className="bg-secondary shadow border-0">
            <CardHeader className="bg-transparent pb-5">
              <div className="text-muted text-center mt-2 mb-3">
                <small>Ingresa los datos necesarios</small>
              </div>
            </CardHeader>
            <CardBody className="px-lg-5 py-lg-5">
              <Form role="form">
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      value={this.state.email}
                      onChange={this.handleChange}
                      name="email"
                      placeholder="email"
                      type="text"
                    />
                  </InputGroup>
                </FormGroup>
                <div className="text-center">
                  <Button
                    className="my-4"
                    color="primary"
                    type="button"
                    disabled={this.validarEnvio()}
                    onClick={() => this.cambio()}
                  >
                    Activar Cuenta
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
          <Row className="mt-3"></Row>
        </Col>
      </>
    );
  }
}

export default Activar;
