import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
} from "reactstrap";

import axios from "axios";

import React, { Component } from "react";
import { altaUsuario } from "../components/utils/Contratos.js";

import { Alert } from "reactstrap";

class RecuperaPass extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mail: "",
      buttonDesactivo: true,
      mostrarAlerta: false,
      mensajeAlert: "",
      color: "danger",
    };
  }

  recuperar = async () => {
    altaUsuario.email = this.state.mail;

    const request = await axios.post(
      global.config.urlBase + "/usuario/olvidepass",
      altaUsuario
    );

    try {
      console.log(request);

      if (request.status === 200) {
        if (request.data.mensaje.estatus) {
          this.setState(
            {
              mostrarAlerta: true,
              mensajeAlert: request.data.mensaje.mensaje,
              color: "success",
            },
            () => {
              let _exQualityInterval = setTimeout(() => {
                this.setState({ mostrarAlert: false });
                clearInterval(_exQualityInterval);
                window.location.href = "/auth/login";
              }, 3000);
            }
          );
        } else {
          this.setState(
            {
              mostrarAlerta: true,
              mensajeAlert: request.data.mensaje.mensaje,
              color: "danger",
            },
            () => {
              let _exQualityInterval = setTimeout(() => {
                this.setState({ mostrarAlert: false });
                clearInterval(_exQualityInterval);
              }, 3000);
            }
          );
        }
      } else {
      }
    } catch {
      this.setState(
        {
          mostrarAlerta: true,
          mensajeAlert: request.data.mensaje.mensaje,
          color: "danger",
        },
        () => {
          let _exQualityInterval = setTimeout(() => {
            this.setState({ mostrarAlert: false });
            clearInterval(_exQualityInterval);
          }, 3000);
        }
      );
    }
  };

  handleChange = (e) => {
    var mail = "";

    if (e.target.name === "mail") {
      mail = e.target.value;

      this.setState({ mail: mail });
    }
    this.validarEnvio();
  };

  validarEnvio = () => {
    if (this.state.mail !== "") {
      let mail = this.state.mail;
      const regex = new RegExp(
        "(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?"
      );

      if (regex.test(mail)) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  };

  componentDidMount = async () => {};

  render() {
    return (
      <>
        <Col lg="5" md="7">
          {this.state.mostrarAlerta ? (
            <Alert color={this.state.color}>
              <strong></strong> {this.state.mensajeAlert}!
            </Alert>
          ) : null}
          <Card className="bg-secondary shadow border-0">
            <CardHeader className="bg-transparent pb-5">
              <div className="text-muted text-center mt-2 mb-3">
                <small>
                  Olvideste tu password, ingresa el mail con el que estas dado
                  de alta
                </small>
              </div>
            </CardHeader>
            <CardBody className="px-lg-5 py-lg-5">
              <Form role="form">
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      value={this.state.mail}
                      onChange={this.handleChange}
                      name="mail"
                      placeholder="Email"
                      type="email"
                      autoComplete="new-email"
                    />
                  </InputGroup>
                </FormGroup>
                <div className="text-center">
                  <Button
                    className="my-4"
                    color="primary"
                    type="button"
                    disabled={this.validarEnvio()}
                    onClick={() => this.recuperar()}
                  >
                    Recuperar
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
          <Row className="mt-3"></Row>
        </Col>
      </>
    );
  }
}

export default RecuperaPass;
