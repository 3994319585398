import {
  Button,
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  Pagination,
  Modal,
  CardBody,
  Form,
  Input,
  Col,
  FormGroup,
  Label,
  Alert,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";

import axios from "axios";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";

import { altaDepartamento } from "../components/utils/Contratos.js";

import React, { Component } from "react";
class Departamentos extends Component {
  constructor(props) {
    super(props);

    this.state = {
      listadoDepartamentos: [],
      exampleModal: false,
      idDepartamento: "",
      idUsuario: "",
      departamento:"",
      departamentoUsuarioWebService:"",
      departamentoPasswordWebService:"",
    };
  }

  componentDidMount = async () => {
    if (!Cookies.get("token")) {
      window.location.href = "/login";
    }

    this.validarExpiracionToken();

    const folios = await axios.get(
      global.config.urlBase +
        "/departamento/listarDepartamentos/" +
        Cookies.get("id"),
      {
        headers: { Authorization: Cookies.get("token") },
      }
    );

    if (folios.status === 200) {
      let listadoDepartamentos = folios.data.resultado;

      this.setState({
        listadoDepartamentos: listadoDepartamentos,
      });
    } else {
    }

    this.setState({ idUsuario: Cookies.get("id") });
  };

  validarExpiracionToken = async () => {
    // VAMOS A VALIDAR LA EXPIRACION DEL TOKEN

    let decoded = jwt_decode(Cookies.get("token"));
    let dateNow = new Date();
    let expiracion = Number(decoded.exp) * 1000;
    let dateToken = new Date(expiracion);

    if (dateToken.getTime() < dateNow.getTime()) {
      window.location.href = "/login";
    }
  };

  llamarFirmantes = (idDepartamento) => {
    window.location.href = "/admin/firmantes/" + idDepartamento;
  };

  validarEnvio = () => {
    if (this.state.departamento !== "" && this.state.departamentoUsuarioWebService !== "" && this.state.departamentoPasswordWebService !== "") {
      return false;
    } else {
      return true;
    }
  };

  cambioCheck = async (idDepartamento) => {
    this.validarExpiracionToken();

    const cambio = await axios.get(
      global.config.urlBase + "/departamento/estatus/" + idDepartamento,
      {
        headers: { Authorization: Cookies.get("token") },
      }
    );

    if (cambio.status === 200) {
      let listadoUsuarios = this.state.listadoUsuarios;

      this.setState({ listadoUsuarios: listadoUsuarios });
    }
  };

  toggleModal = (state,add) => {

    if(add){
    console.log("creando departamento");
    this.setState({[state]: !this.state[state],departamento:"",departamentoUsuarioWebService:"",departamentoPasswordWebService:""});
    }else{
      this.setState({[state]: !this.state[state]});
    }
    
  };

  renderTableData() {
    if (this.state.listadoDepartamentos.length > 0) {
      return this.state.listadoDepartamentos.map((departamento, index) => {
        return (
          <tr key={index}>
            <td className="text-center">{departamento.departamento}</td>

            <td className="text-center">
              <label className="custom-toggle">
                <input
                  defaultChecked={departamento.estatus ? true : false}
                  onChange={() => this.cambioCheck(departamento.identificador)}
                  type="checkbox"
                />
                <span className="custom-toggle-slider rounded-circle" />
              </label>
            </td>

            <td className="text-center">
              <Button
                className="btn-icon btn-3"
                color="primary"
                type="button"
                onClick={() =>
                  this.llamarFirmantes(departamento.identificador)
                }
              >
                <span className="btn-inner--icon">
                  <i className="ni ni-badge" />
                </span>
              </Button>
            </td>

            <td className="text-center">
              <Button
                className="btn-icon btn-3"
                color="primary"
                type="button"
                onClick={() =>
                  this.editarDepartamento(departamento.identificador, true)
                }
              >
                <span className="btn-inner--icon">
                  <i className="ni ni-ui-04" />
                </span>
              </Button>
            </td>
          </tr>
        );
      });
    }
  }

  editarDepartamento = (identificador, edicion) => {

    console.log("este es el id del departamento");
    console.log(identificador);
    if (!edicion) {
      this.setState({ departamento: "" });
    } else {
      let departamento = this.state.listadoDepartamentos.filter(
        (elemento) => elemento.identificador === identificador
      );

      console.log("departamento");
      console.log(departamento);

      let departamentoTexto = departamento[0].departamento;
      let departamentoUsuario = departamento[0].usuarioWebService;
      let departamentoPass = departamento[0].passwordWebService;
      console.log("departamentoTexto");
      console.log(departamentoTexto);

      this.setState({
        departamento: departamentoTexto,
        idDepartamento: identificador,
        departamentoUsuarioWebService:departamentoUsuario,
        departamentoPasswordWebService:departamentoPass
      });
    }

    this.toggleModal("exampleModal");
  };

  handleChange = (e) => {
    var departamento = "";
    var departamentoUsuario = "";
    var departamentoPassword = "";

    if (e.target.name === "departamento") {
      departamento = e.target.value;

      this.setState({ departamento: departamento });
    }

    if (e.target.name === "departamentoUsuario") {
      departamentoUsuario = e.target.value;

      this.setState({ departamentoUsuarioWebService: departamentoUsuario });
    }


    if (e.target.name === "departamentoPassword") {
      departamentoPassword = e.target.value;

      this.setState({ departamentoPasswordWebService: departamentoPassword });
    }               

    this.validarEnvio();
  };

  crearDepartamento = async () => {

    this.toggleModal("exampleModal");

    this.validarExpiracionToken();

    altaDepartamento.identificador = this.state.idDepartamento;
    altaDepartamento.departamento = this.state.departamento;
    altaDepartamento.idUsuario = this.state.idUsuario;
    altaDepartamento.usuarioWebService = this.state.departamentoUsuarioWebService;
    altaDepartamento.passwordWebService = this.state.departamentoPasswordWebService;


    try {
      const alta = await axios.post(
        global.config.urlBase + "/departamento/crear",
        altaDepartamento,
        {
          headers: { Authorization: Cookies.get("token") },
        }
      );

      if (alta.status === 200) {
        this.setState(
          {
            mostrarAlerta: true,
            mensajeAlerta: alta.data.mensaje.mensaje,
            color: "success",
          },
          () => {
            let _exQualityInterval = setTimeout(() => {
              this.setState({ mostrarAlerta: false });
              clearInterval(_exQualityInterval);
              this.componentDidMount();
            }, 3000);
          }
        );
      }
    } catch {
      this.setState(
        {
          mostrarAlerta: true,
          mensajeAlerta: "PROBLEMA AL CREAR USUARIO INTENTE MAS TARDE",
          color: "danger",
        },
        () => {
          let _exQualityInterval = setTimeout(() => {
            this.setState({ mostrarAlerta: false });
            clearInterval(_exQualityInterval);
          }, 3000);
        }
      );
    }
  };

  render() {
    return (
      <>
        <Header />
        {/* Page content */}
        <Container className="mt--7" fluid>
          {/* Table */}

          {this.state.mostrarAlerta ? (
            <Alert color={this.state.color}>
              <strong></strong> {this.state.mensajeAlerta}!
            </Alert>
          ) : null}
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <h3 className="mb-0">Departamentos</h3>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col" className="text-center">
                        Departamento
                      </th>
                      <th scope="col" className="text-center">
                        Estatus
                      </th>
                      <th scope="col" className="text-center">
                        Firmantes
                      </th>
                      <th scope="col" className="text-center">
                        Editar
                      </th>

                      <th scope="col" />
                    </tr>
                  </thead>
                  <tbody>{this.renderTableData()}</tbody>
                </Table>
                <CardFooter className="py-4">
                  <nav aria-label="...">
                    <Pagination
                      className="pagination justify-content-end mb-0"
                      listClassName="justify-content-end mb-0"
                    >
                      <Button
                        className="btn-icon btn-3"
                        color="primary"
                        type="button"
                        onClick={() => this.toggleModal("exampleModal",true)}
                      >
                        <span className="btn-inner--icon">
                          <i className="ni ni-fat-add" />
                        </span>
                      </Button>
                    </Pagination>
                  </nav>
                </CardFooter>
              </Card>
            </div>
          </Row>

          <Modal
            className="modal-dialog-centered"
            size="xs"
            isOpen={this.state.exampleModal}
            toggle={() => this.toggleModal("exampleModal")}
          >
            <div className="modal-body p-0">
              <Card className="bg-secondary shadow border-0">
                <CardHeader className="bg-transparent pb-5">
                  <div className="text-muted text-center mt-2 mb-3">
                    <small>Agregar o editar departamento</small>
                  </div>
                </CardHeader>
                <CardBody className="px-lg-5 py-lg-5">
                  <Form>
                    <Row>
                      <Col md="12">
                        <FormGroup>
                          <Label>Departamento</Label>
                          <Input
                            value={this.state.departamento}
                            onChange={this.handleChange}
                            name="departamento"
                            placeholder="departamento"
                            type="text"
                          />
                        </FormGroup>

                        <FormGroup>
                          <Label>Usuario WebService</Label>
                          <Input
                            value={this.state.departamentoUsuarioWebService}
                            onChange={this.handleChange}
                            name="departamentoUsuario"
                            placeholder="departamentoUsuario"
                            type="text"
                          />
                        </FormGroup>


                        <FormGroup>
                          <Label>Password WebService</Label>
                          <Input
                            value={this.state.departamentoPasswordWebService}
                            onChange={this.handleChange}
                            name="departamentoPassword"
                            placeholder="departamentoPassword"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </div>

            <div className="modal-footer">
              <Button
                color="primary"
                type="button"
                disabled={this.validarEnvio()}
                onClick={() => this.crearDepartamento()}
              >
                Guardar
              </Button>
              <Button
                className="ml-auto"
                color="link"
                data-dismiss="modal"
                type="button"
                onClick={() => this.toggleModal("exampleModal")}
              >
                Cancelar
              </Button>
            </div>
          </Modal>
        </Container>
      </>
    );
  }
}

export default Departamentos;
