import {
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Label,
  Row,
  Col,
} from "reactstrap";

import axios from "axios";

import React, { Component } from "react";

import { Alert } from "reactstrap";

class InfoFolio extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firmante: "",
      solicitante: "",
      fecha: "",
      mostrarAlerta: false,
      tipoTramite: "",
      mensajeAlert: "",
      tramite:
        this.props.match.params.id === ":id" ? "" : this.props.match.params.id,
    };
  }

  componentDidMount = async () => {
    const request = await axios.get(
      global.config.urlBase + "/consultar/" + this.state.tramite
    );

    if (request.status === 200) {
      if (request.data.resultado) {
        let firmante =
          request.data.resultado[0].nombreFirmante +
          " " +
          request.data.resultado[0].apellidoPaternoFirmante +
          " " +
          request.data.resultado[0].apellidoMaternoFirmante;
        let solicitante =
          request.data.resultado[0].nombreSolicitante +
          " " +
          request.data.resultado[0].apellidoPaternoSolicitante +
          " " +
          request.data.resultado[0].apellidoMaternoSolicitante;
        let fecha = request.data.resultado[0].fechaHora;
        let tipoTramite = request.data.resultado[0].tipoTramite;
        let departamento = request.data.resultado[0].departamento;
        let rfc = request.data.resultado[0].rfc;
        let fechaVencimiento = request.data.resultado[0].fechaVencimiento;

        if(fecha!= null){

          console.log('fecha diferente de null');
          fecha =this.formatoFecha(fecha);

        }
        if(fechaVencimiento!= null){

          console.log('fecha diferente de null');
          fechaVencimiento =this.formatoFecha(fechaVencimiento);

        }

        this.setState({
          firmante: firmante,
          solicitante: solicitante,
          fecha: fecha,
          tipoTramite: tipoTramite,
          departamento: departamento,
          rfc: rfc,
          fechaVencimiento: fechaVencimiento,
        });
      } else {
        this.setState({
          mostrarAlerta: true,
          mensajeAlert: request.data.mensaje.mensaje,
        });
      }
    }
  };


  formatoFecha = (fecha) => {

    console.log('entrando a fecha con ');
    console.log(fecha);

    let fechaFormato = '';

    let format = fecha.split("T");
    console.log(format);

    let horas = format[1].split(".")
    console.log(horas);
    fechaFormato = format[0] + ' ' + horas[0]
    console.log('regresando ');
    console.log(fechaFormato);
    return fechaFormato;
  }

  render() {
    return (
      <>
        <Col lg="5" md="7">
          {this.state.mostrarAlerta ? (
            <Alert color="danger">
              <strong>Error!</strong> {this.state.mensajeAlert}!
            </Alert>
          ) : null}
          <Card className="bg-secondary shadow border-0">
            <CardHeader className="bg-transparent pb-5">
              <div className="text-muted text-center mt-2 mb-3">
                <small>Informacion de la firma</small>
              </div>
            </CardHeader>
            <CardBody className="px-lg-5 py-lg-5">
              <Form>
                <Row>
                  <Col md="12">
                    <FormGroup>
                      <Label for="exampleEmail">Solicitante</Label>
                      <Input
                        disabled
                        value={this.state.solicitante}
                        type="text"
                      />
                    </FormGroup>
                  </Col>
                  <Col md="12">
                    <FormGroup>
                      <Label for="exampleEmail">Firmante</Label>
                      <Input value={this.state.firmante} disabled type="text" />
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col md="12">
                    <FormGroup>
                      <Label for="exampleEmail">Tipo tramite</Label>
                      <Input
                        type="text"
                        value={this.state.tipoTramite}
                        disabled
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col md="12">
                    <FormGroup>
                      <Label for="exampleEmail">Departamento</Label>
                      <Input
                        type="text"
                        value={this.state.departamento}
                        disabled
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col md="12">
                    <FormGroup>
                      <Label for="exampleEmail">RFC</Label>
                      <Input type="text" value={this.state.rfc} disabled />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <FormGroup>
                      <Label for="exampleEmail">Fecha Firma</Label>
                      <Input type="text" value={this.state.fecha} disabled />
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col md="12">
                    <FormGroup>
                      <Label for="exampleEmail">Vigencia del Documento</Label>
                      <Input
                        type="text"
                        value={this.state.fechaVencimiento}
                        disabled
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>
          <Row className="mt-3"></Row>
        </Col>
      </>
    );
  }
}

export default InfoFolio;
